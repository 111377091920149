.noResponses {
  & > h3 {
    color: white;
    letter-spacing: 0.1rem;
    font-size: 1.5rem;
    font-weight: lighter;
    text-align: center;
  }
}

.title {
  color: white;
  letter-spacing: 0.1rem;
  font-size: 1.5rem;
  font-weight: lighter;
  text-align: left;
  margin: 0;
  margin-top: 2rem;
}

.responses {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
