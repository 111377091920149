@use "../../App.scss" as App;
@import "../../App.scss";
.wrapper {
  background-color: App.$floral-white;
  width: 60%;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  margin: 1rem;
  position: relative;
  transition: all 0.3s ease-in-out;
  @include xs {
    width: 80%;
  }

  & > .content {
    text-align: justify;
    & > .date {
      display: inline;
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      @include xs {
        font-size: 0.7rem;
      }
    }
    & > div {
      margin-bottom: 1rem;
    }

    .subtitle {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 1rem;
      @include xs {
        font-size: 0.8rem;
      }
    }

    .text {
      font-size: 1.2rem;
      @include xs {
        font-size: 0.8rem;
      }
    }
    .wrap {
      min-width: 120px;
      @include xs {
        min-width: 70px;
      }
    }
    .prompt {
      display: flex;
    }
    .response {
      display: flex;
    }
  }
}
