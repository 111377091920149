@import "../../App.scss";
.wrapper {
  margin-left: 2rem;
  & > .title {
    color: white;
  }
  & > div {
    @include gt-xs {
      min-width: 200px;
    }
  }
}
