@use "../../App.scss" as App;
.content {
  padding: 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    li {
      margin-right: 2rem;
      a {
        color: App.$dark-orange;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        transition: all 0.2s ease-in-out;
        &:hover {
          color: App.$orange-yellow-crayola;
        }
      }
    }
  }
}
