button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
  &:disabled {
    background-color: #eee;
    color: #ccc;
    cursor: not-allowed;
  }
}
