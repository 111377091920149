@use "../../App.scss" as App;
@import "../../App.scss";

.wrapper {
  width: 60vw;
  height: 100%;
  min-height: 100vh;
  background-color: App.$tertiary;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include xs {
    width: 100vw;
  }
}
