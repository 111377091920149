.prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > button {
    margin-top: 1rem;
    width: 25%;
  }
}
