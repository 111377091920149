@use "../../App.scss" as App;
.wrapper {
  & > .title {
    color: white;
    border: none;
    font-size: 1.1rem;
    font-weight: lighter;
  }
  & > textarea {
    padding: 1rem 3rem;
    border: none;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    &:hover {
      outline: App.$main solid 0.2rem;
    }
    &:focus {
      outline: App.$secondary solid 0.2rem;
    }
  }
}
