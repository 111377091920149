@import "media-queries.scss";
/* SCSS HSL */
$dark-orange: hsla(30, 100%, 53%, 1);
$orange-yellow-crayola: hsla(44, 86%, 70%, 1);
$floral-white: hsla(32, 100%, 97%, 1);
$middle-blue-green: hsla(173, 44%, 73%, 1);
$space-cadet: hsla(254, 31%, 27%, 1);

$main: #b983ff;
$secondary: #94b3fd;
$tertiary: #94daff;
$quaternary: #99feff;

html {
  overflow-x: hidden;
}
.App {
  display: flex;
  justify-content: center;
  background-color: $secondary;
  min-height: 100vh;
  width: 100vw;
  h1 {
    color: white;
  }
}

.loadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(#000000, 0.3);
  z-index: 1;
  width: 100vw;
}
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
